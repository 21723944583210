<template>
	<h1>Form Radio Buttons</h1>
	<div class="text-block bump-b-30">
		<pre v-highlightjs><code class="language-html">{{html}}</code></pre>
		<pre v-highlightjs><code class="language-javascript">import FormRadios from '@/components/FormRadios'

export default {
	components: { FormRadios },
	data() {
		return {
			vegetable: null,
			radioOptions: [
				{
					text: 'Cauliflower',
					value: 'cauliflower',
					checked: true,		// &#60;-- Default value
				},
				{
					text: 'Asparagus',
					value: 'asparagus',
				},
				{
					text: 'Red Beets',
					value: 'red-beets',
				}
			]
		}
	}
}
</code></pre>
	</div>
	<div class="text-block bump-b-30">
		<h3>Demo</h3>
		<p>
			<a href="#" @click.prevent="setError">Click here</a> to trigger error state. Change the radio to remove
			it.
		</p>
		<p>
			Selected vegetable: <em>{{ vegetable }}</em>
		</p>
		<br />

		<FormRadios
			v-model="vegetable"
			:radioOptions="radioOptions"
			name="vegetable"
			label="Vegetables"
			@update="resetError"
		/>
		<br />
		<p class="error-msg small">{{ errors.general }}</p>
	</div>
</template>

<script>
import FormRadios from '@/components/FormRadios'
import { injectMetaData } from '@/use/MetaData'

export default {
	name: 'DocFormsRadio',
	components: { FormRadios },
	setup() {
		injectMetaData({
			title: 'Doc: Forms › Radio Buttons',
		})
	},
	data() {
		return {
			vegetable: null,
			radioOptions: [
				{
					text: 'Cauliflower',
					value: 'cauliflower',
					checked: true,
				},
				{
					text: 'Asparagus',
					value: 'asparagus',
				},
				{
					text: 'Red Beets',
					value: 'red-beets',
				},
			],
			errors: { general: null },
			html: [
				'<FormRadios',
				'	v-model="vegetable"				// Data model',
				'	:radioOptions="radioOptions"	// Radio contents',
				'	name="vegetable"				// Name identifier',
				'	label="Vegetable"				// If not set, no label will be rendered',
				'	@update="resetError"			// Reset error state on edit',
				'									',
				'									// Shared:',
				'	:mT/mR/mB/mL="30"				// Set single margin',
				'	:margin="[10,10,10,10]"			// Set all margins at once',
				'	:width="300"					// Default width is 250',
				'/>',
			].join('\n'),
		}
	},
	methods: {
		setError() {
			this.errors.general = "Something's wrong."
		},
		resetError() {
			this.errors.general = ''
		},
	},
}
</script>

<style scoped lang="scss"></style>
